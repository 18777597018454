import Navigo from 'navigo';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// const feather = require('feather-icons');

// getElementById wrapper
function getId(id) {
    return document.getElementById(id);
}
// asyncrhonously fetch the html template partial from the file directory, then set its contents to the html of the parent element
function loadHTML(url, id) {
    // alert(url+' / '+id);
    let req = new XMLHttpRequest();
    req.open('GET', url);
    req.send();
    req.onload = () => {
        getId(id).innerHTML = req.responseText;
    };
}
function setPageAfterload(link) {
    console.log(link);
    // Remove active state to all sidebar nav links and collapse toggles then...
    $("#layoutSidenav_nav .sidenav a.nav-link").not('[data-toggle="modal"]').each(function() {
        $(this).removeClass("active");
        if(this.getAttribute("data-bs-toggle")=='collapse') {
            this.classList.add('collapsed');
            this.nextElementSibling.classList.remove('show'); // <=> $(this).next('.collapse').removeClass('show');
        }
    });
    // Add active state to sidebar nav links
    const targetAnchors = document.body.querySelectorAll('[href="/' + link + '"].nav-link');
    targetAnchors.forEach(targetAnchor => {
        let parentNode = targetAnchor.parentNode;
        while (parentNode !== null && parentNode !== document.documentElement) {
            if (parentNode.classList.contains('collapse')) {
                parentNode.classList.add('show');
                const parentNavLink = document.body.querySelector(
                    '[data-bs-target="#' + parentNode.id + '"]'
                );
                parentNavLink.classList.remove('collapsed');
                parentNavLink.classList.add('active');
            }
            parentNode = parentNode.parentNode;
        }
        targetAnchor.classList.add('active');
    });
    if(link=='index.html') $('.nav-link-def-route').addClass('active').closest('.collapse').addClass('show').prev('.nav-link').removeClass('collapsed').addClass('active');
    // Now doing things like binding UI Actcions for this page...
    feather.replace(); // Activate Feather icons
    App.displayUserTypesRelated(); // Display .adminOnly, .accountantOnly... Objects
    sessionStorage.setItem('myPage', link); // Change current page to session
    App.refreshSmoothScroll(); // Smooth Scroll to div...
    App.enablePopovers(); // Enable popovers...
    // Add the following code if you want the name of the file appear on select
    $(".custom-file-input").on("change", function(e) {
        //const fileName = $(this).val().split("\\").pop();
        let fileName = "";
        //e.target.files.forEach(function(item){ fileName += '"'+item.name+'"';});
        //alert(e.target.files[0].name);
        for (var i = 0; i < e.target.files.length; i++) {
            fileName += '"'+e.target.files[i].name+'" ';
        }
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
    const isMobile = (/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<768) ? true : false;
    if(isMobile) {
        $('#myTab').removeClass('nav-tabs').addClass('nav-pills');
    }
}
function destroyDataTables() {
    // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
    $('.dataTable').DataTable().destroy(true);
}
// Now the router... Depending on login
if(localStorage.getItem('pass')=='OK' && document.URL.indexOf( 'login.html' ) === -1) {
    const myDomain = window.location.origin; // https://my.domain
    const rootUrl = (myDomain.includes('localhost')) ? '/' : '/'; // if online version is in a subfolder
    const router = new Navigo(rootUrl); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    // use #! to hash
    // const router = new Navigo(null, true, '#!'); // params are: root, useHash (Defaults to: false) and hash (Defaults to: '#')
    router.hooks({
        // Ensure new data-navigo links work.
        after: function() {
            router.updatePageLinks();
        },
    });
    const routerContainer = document.getElementById('routerContainer');
    const routerContainerExist = (routerContainer) ? true : false;
    if(!routerContainerExist) document.location.href = '/';
    // Direct To Some Page...
    // const goTo = App.urlParam('goto', document.URL); // Where are we going
    // if(goTo == 'page') router.navigate("/page");

    router.on({
        // 'routerContainer' is the id of the div element inside which we render the HTML
        '/': () => {
            destroyDataTables();
            fetch('/home.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('home');
                App.setHomePage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/home': () => {
            destroyDataTables();
            fetch('/home.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('home');
                App.setHomePage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/buy/invoices': () => {
            destroyDataTables();
            fetch('/buy-invoices.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setBuyInvoicesListPage();
                setPageAfterload('buy/invoices');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/buy/invoices/:id': ({data}) => {
            destroyDataTables();
            fetch('/buy-invoice.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setBuyInvoicePage(data.id);
                setPageAfterload('buy/invoices');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/clients': () => {
            destroyDataTables();
            fetch('/clients.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setClientsListPage();
                setPageAfterload('clients');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/clients/:id': ({data}) => {
            destroyDataTables();
            fetch('/client.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setClientPage(data.id);
                setPageAfterload('clients');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/contacts': ({data}) => {
            destroyDataTables();
            fetch('/contacts.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setContactsListPage();
                setPageAfterload('contacts');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/contacts/:id': ({data}) => {
            fetch('/contact.html').then(function (response) {
                return response.text(); // To fetch html below we fetch json as we usually do...
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setContactPage(data.id);
                setPageAfterload('contacts');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/dashboard': () => {
            destroyDataTables();
            fetch('/dashboard.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('dashboard');
                App.setDashPage();
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/deliveries': () => {
            destroyDataTables();
            fetch('/deliveries.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setDeliveriesListPage();
                setPageAfterload('deliveries');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/deliveries/:id': ({data}) => {
            destroyDataTables();
            fetch('/delivery.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setDeliveryPage(data.id);
                setPageAfterload('deliveries');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/events/:id': ({data}) => {
            destroyDataTables();
            fetch('/event.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setEventPage(data.id);
                setPageAfterload('planning');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/links': () => {
            destroyDataTables();
            fetch('/links.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setLinksListPage();
                setPageAfterload('links');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/links/:search': ({data}) => {
            destroyDataTables();
            fetch('/links.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setLinksSearchPage(data.search);
                setPageAfterload('links');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/mails': () => {
            destroyDataTables();
            fetch('/mails.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setMailsListPage();
                setPageAfterload('mails');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/mails/models': () => {
            destroyDataTables();
            fetch('/mails-models.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setMailsModelsListPage();
                setPageAfterload('mails/models');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/phone': () => {
            destroyDataTables();
            fetch('/client.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.contactsPhoneSearch();
                setPageAfterload('contacts');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/planning': () => {
            destroyDataTables();
            fetch('/planning.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setPlanningPage();
                setPageAfterload('planning');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/products': () => {
            destroyDataTables();
            fetch('/products.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setProductsListPage();
                setPageAfterload('products');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/products/:id': ({data}) => {
            destroyDataTables();
            fetch('/product.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setProductPage(data.id);
                setPageAfterload('products');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/reports': () => {
            destroyDataTables();
            fetch('/reports.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setReportsListPage();
                setPageAfterload('reports');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/reports/:id': ({data}) => {
            destroyDataTables();
            fetch('/report.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setReportPage(data.id);
                setPageAfterload('reports');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/projects': () => {
            destroyDataTables();
            fetch('/sales-projects.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setProjectsListPage();
                setPageAfterload('sales/projects');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/projects/:id': ({data}) => {
            destroyDataTables();
            fetch('/sales-project.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setProjectPage(data.id);
                setPageAfterload('sales/projects');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/quotations': () => {
            destroyDataTables();
            fetch('/sales-quotations.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setQuotationsListPage();
                setPageAfterload('sales/quotations');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/quotations/:id': ({data}) => {
            destroyDataTables();
            fetch('/sales-quotation.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setQuotationPage(data.id);
                setPageAfterload('sales/quotations');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/invoices': () => {
            destroyDataTables();
            fetch('/sales-invoices.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setInvoicesListPage();
                setPageAfterload('sales/invoices');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/invoices/:id': ({data}) => {
            destroyDataTables();
            fetch('/sales-invoice.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setInvoicePage(data.id);
                setPageAfterload('sales/invoices');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/sales/follow-up': () => {
            destroyDataTables();
            fetch('/sales-follow-up.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSalesFollowUpListPage();
                setPageAfterload('sales/follow-up');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/scenes': () => {
            destroyDataTables();
            fetch('/scenes.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setScenesListPage();
                setPageAfterload('scenes');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/search': () => {
            destroyDataTables();
            fetch('/search.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSearchPage('', true);
                setPageAfterload('search');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/search/:search': ({data}) => {
            destroyDataTables();
            fetch('/search.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSearchPage(data.search, true);
                setPageAfterload('search');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/stocks': () => {
            destroyDataTables();
            fetch('/stocks.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setStocksListPage('', true);
                setPageAfterload('stocks');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/stocks/:id': ({data}) => {
            destroyDataTables();
            fetch('/stock.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setStockPage(data.id);
                setPageAfterload('stocks');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/uploads': () => {
            destroyDataTables();
            fetch('/uploads.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUploadsListPage('', true);
                setPageAfterload('uploads');
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/users': () => {
            destroyDataTables();
            fetch('/users.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUsersListPage();
                setPageAfterload('users');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/users/:id': ({data}) => {
            destroyDataTables();
            fetch('/user.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setUserPage(data.id);
                setTimeout(()=>{
                    if(data.id==localStorage.getItem('id')) setPageAfterload('users/'+data.id);
                    else setPageAfterload('users');
                },1000)
                // router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/org': () => {
            destroyDataTables();
            fetch('/org.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setOrganizationPage();
                setPageAfterload('org');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/settings': () => {
            destroyDataTables();
            fetch('/settings.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSettingsPage();
                setPageAfterload('settings');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/settings/:id': ({data}) => {
            destroyDataTables();
            fetch('/settings.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                App.setSettingsPage(data.id);
                setPageAfterload('settings');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/404': () => {
            destroyDataTables();
            fetch('/404.html').then(function (response) {
                return response.text();
            }).then(function (html) {
                routerContainer.innerHTML = html;
                setPageAfterload('404');
                router.updatePageLinks(); // In case there are data-navigo links on the loaded page
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            });
        },
        '/dc': () => {
            App.logMeOut();
            // loadHTML('/logout.html', 'routerContainer');
        },
    });

    router.on(() => {
        destroyDataTables();
        fetch('/home.html').then(function (response) {
            return response.text();
        }).then(function (html) {
            routerContainer.innerHTML = html;
            App.setHomePage();
            setPageAfterload('index.html');
            router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });
    });

    // set the 404 route
    router.notFound((query) => {
        loadHTML('/404.html', 'routerContainer');
        setPageAfterload('404');
        router.updatePageLinks(); // In case there are data-navigo links on the loaded page
        console.warn('Route not found');
        console.warn(query);
    });

    router.resolve();
    /*
    fetch('http://example.org').then(function(response) {
        if (response.ok) {
            return response.json()
        } else {
            throw ("Error " + response.status);
        }
    }).then(function(data) {
        // do what you have to do with data
    }).catch(function(err) {
        console.log(err);
    });
    */
}